const translate = {
    header: {
        our_apps: 'Наши приложения',
        company: 'Компания',
        blog: 'Блог',
        contacts: 'Контакты',
    },
    footer: {
        our_products: 'Наши продукты',
        company: 'Компания',
        about_company: 'О компании',
        blog: 'Блог',
        support: 'Помощь',
        policy: 'Политика',
        privacy_policy: 'Политика Конфиденциальности',
        cookies_policy: 'Политика Cookie',
        follow_us: 'Подписывайтесь',
    },
    home: {
        our_apps__title: 'Наши приложения',
        our_apps__desc: 'Отличные приложения для вашего телефона и образа жизни',
        our_apps__new_desc: 'Новое приложение уже в разработке!',
        company__title: 'Компания',
        company__desc: 'Мы, Yavorsky Team, стремимся создавать инновационные и ориентированные на пользователя приложения для iOS, превосходящие ожидания стандартных утилит. Благодаря команде экспертов, занимающихся созданием высококачественных продуктов, наши приложения функциональны, надежны и предназначены для обеспечения приятного пользовательского опыта.',
        company__link: 'Узнайте больше о нас',
        company__network_title: 'Следите за нами в социальных сетях',
        form_contact_title: 'Свяжитесь с нами',
        form_contact_desc: 'Если у вас есть вопросы, пожалуйста, свяжитесь с нами, используя эту форму',
        user_review__title: 'Отзывы наших пользователей',
        user_review__desc: 'Присоединяйтесь к нашему растущему списку довольных пользователей: узнайте,<br />почему все больше и больше людей выбирают нас',
        subscribe_title: 'Подпишитесь, чтобы быть первым',
        subscribe_desc: 'Получайте эксклюзивные предложения и обновления наших приложений,<br />доставленные прямо на вашу почту нашей надежной почтовой совой',
        subscribe_btn: 'Подписаться',
        soon_on_app_store: 'Скоро в App Store',
        coming_soon: 'Скоро',
    },
    blog: {
        title: 'Блог',
        desc: 'Будьте в курсе последних новостей,<br />тенденций и идей в нашем блоге',
        see_all: 'Все статьи',
        search: 'Поиск',
        rubric_all: 'Все статьи',
        rubric_news: 'Новости',
        rubric_how_to: 'How To',
        latest_posts: 'Последние публикации',
        no_results_found: 'Результаты не найдены',
        all_results: 'Все результаты',
        social_title: 'Понравилась статья?',
        social_desc: 'Поделитесь постом или подпишитесь на нашу рассылку',
        subscribe_btn: 'Подписаться',
    },
    common: {
        email: 'Электронная почта',
        name: 'Имя',
        form_comment: 'Дайте нам знать, о чем вы думаете',
        send: 'Отправить',
        i_agree_with_1: 'Я согласен с',
        i_agree_with_2: 'Политикой Конфиденциальности',
        message_show_all_errors: 'Пожалуйста исправьте указанные ошибки',
        learn_more: 'Подробнее',
        free_download: 'Скачать',
        download: 'Скачать',
        years: 'лет',
        on_market: 'в App Store',
        app_downloads: 'скаченых приложений',
        average_rate: 'средняя оценка',
        page_contact__desc: 'Если у вас есть какие-либо вопросы, комментарии или замечания, пожалуйста, свяжитесь с нами.',
        contact_form__file_desc_1: 'Перетащите сюда',
        contact_form__file_desc_2: 'или Поиск файла',
        thank_you: 'Спасибо',
        modal_successfully_submitted: 'Форма успешно отправлена!',
        modal_successfully_subscribed: 'Вы успешно подписались',
        modal_successfully_sent: 'Форма успешно отправлена',
        table_of_contents: "Содержание",
        updated: "Обновлено",
        contact_us: "Написать",
        still_have_questions: "Остались вопросы?",
        still_have_questions__msg: "Свяжитесь с нами и мы ответим вам как можно скорее",
        published: 'Опубликовано',
        coockie_desc: 'Этот сайт использует файлы cookie, чтобы обеспечить вам наилучшие опыт от использования нашего сайта.',
        coockie_btn: 'Принять cookies',
    },
    languages: {
        language: 'Язык',
        english: 'Английский',
        russian: 'Русский',
        ukrainian: 'Украинский',
    },
}
export default translate
