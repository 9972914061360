const translate = {
    header: {
        our_apps: 'Наші застосунки',
        company: 'Компанія',
        blog: 'Блог',
        contacts: 'Контакти',
    },
    footer: {
        our_products: 'Наші продукти',
        company: 'Компанія',
        about_company: 'Про компанію',
        blog: 'Блог',
        support: 'Допомога',
        policy: 'Політика',
        privacy_policy: 'Політика Конфіденційності',
        cookies_policy: 'Політика Cookie',
        follow_us: 'Підписуйтеся',
    },
    home: {
        our_apps__title: 'Наші застосунки',
        our_apps__desc: 'Відмінні програми для вашого телефону та способу життя',
        our_apps__new_desc: 'Новий застосунок вже в розробці!',
        company__title: 'Компанія',
        company__desc: 'Ми, Yavorsky Team, прагнемо створювати інноваційні та орієнтовані на користувача програми для iOS, що перевершують очікування стандартних утиліт. Завдяки команді експертів, які займаються створенням високоякісних продуктів, наші додатки функціональні, надійні та призначені для забезпечення приємного користувальницького досвіду.',
        company__link: 'Дізнайтеся більше про нас',
        company__network_title: 'Слідкуйте за нами в соціальних мережах',
        form_contact_title: "Зв'яжіться з нами",
        form_contact_desc: 'Якщо у вас є питання, будь ласка, зв\'яжіться з нами за допомогою цієї форми',
        user_review__title: 'Відгуки наших користувачів',
        user_review__desc: 'Приєднуйтесь до нашого списку задоволених користувачів: дізнайтеся,<br />чому все більше і більше людей вибирають нас',
        subscribe_title: 'Підпишіться, щоб бути першим',
        subscribe_desc: 'Отримуйте ексклюзивні пропозиції та оновлення наших додатків,<br />доставлені прямо на вашу пошту нашим надійним поштовим совеням',
        subscribe_btn: 'Підписатися',
        soon_on_app_store: 'Незабаром в App Store',
        coming_soon: 'Незабаром',
    },
    blog: {
        title: 'Блог',
        desc: 'Будьте в курсі останніх новин,<br />тенденцій та ідей у нашому блозі',
        see_all: 'Усі статті',
        search: 'Пошук',
        rubric_all: 'Усі статті',
        rubric_news: 'Новини',
        rubric_how_to: 'How To',
        latest_posts: 'Останні публікації',
        no_results_found: 'Результати не знайдено',
        all_results: 'Усі результати',
        social_title: 'Сподобалася стаття?',
        social_desc: 'Поділіться постом або підпишіться на нашу розсилку',
        subscribe_btn: 'Підписатися',
    },
    common: {
        email: 'Електронна пошта',
        name: "Ім'я",
        form_comment: 'Повідомте нам про що ви думаєте',
        send: 'Надіслати',
        i_agree_with_1: 'Я погоджуюся з',
        i_agree_with_2: 'Політикою Конфіденційності',
        message_show_all_errors: 'Будь ласка, виправте вказані помилки',
        learn_more: 'Детальніше',
        free_download: 'Завантажити',
        download: 'Завантажити',
        years: 'років',
        on_market: 'в App Store',
        app_downloads: 'завантажених застосунків',
        average_rate: 'середня оцінка',
        page_contact__desc: 'Якщо у вас є питання, коментарі або зауваження, будь ласка, зв\'яжіться з нами.',
        contact_form__file_desc_1: 'Перетягніть сюди',
        contact_form__file_desc_2: 'або Пошук файлу',
        thank_you: 'Дякуємо',
        modal_successfully_submitted: 'Форма успішно відправлена!',
        modal_successfully_subscribed: 'Ви успішно підписалися',
        modal_successfully_sent: 'Форма успішно відправлена',
        table_of_contents: "Зміст",
        updated: "Оновлено",
        contact_us: "Написати",
        still_have_questions: "Залишилися питання?",
        still_have_questions__msg: "Зв'яжіться з нами і ми відповімо вам якнайшвидше",
        published: 'Опубликовано',
        coockie_desc: 'Цей сайт використовує файли cookie, щоб забезпечити вам найкращий досвід від використання нашого сайту.',
        coockie_btn: 'Прийняти cookies',
    },
    languages: {
        language: 'Мова',
        english: 'Англійська',
        russian: 'Російська',
        ukrainian: 'Українська',
    },
}
export default translate
