const translate = {
    header: {
        our_apps: 'Our apps',
        company: 'Company',
        blog: 'Blog',
        contacts: 'Contacts',
    },
    footer: {
        our_products: 'Our Products',
        company: 'Company',
        about_company: 'About the company',
        blog: 'Blog',
        support: 'Support',
        policy: 'Policy',
        privacy_policy: 'Privacy Policy',
        cookies_policy: 'Cookies Policy',
        follow_us: 'Follow us',
    },
    home: {
        our_apps__title: 'Our apps',
        our_apps__desc: 'Great apps for your phone and your lifestyle',
        our_apps__new_desc: 'New app is coming soon!',
        company__title: 'Company',
        company__desc: 'We, the Yavorsky Team, are committed to creating innovative and user-centric iOS apps that exceed the expectations of standard utilities. With a team of experts dedicated to creating high-quality products, our apps are functional, reliable and designed to provide a pleasant user experience.',
        company__link: 'Find out more about us',
        company__network_title: 'Follow us across the web',
        form_contact_title: 'Get in touch with us',
        form_contact_desc: 'If you have questions please contact us using this form',
        user_review__title: 'Our users reviews',
        user_review__desc: 'Join our growing list of satisfied users: discover<br />why more and more clients are choosing us',
        subscribe_title: 'Subscribe to be the first',
        subscribe_desc: 'Receive YavorskyTeam\'s exclusive offers and updates on our latest apps, <br />delivered straight to your inbox by our trusty delivery owl',
        subscribe_btn: 'Subscribe',
        soon_on_app_store: 'Soon on App Store',
        coming_soon: 'Coming soon',
    },
    blog: {
        title: 'Blog',
        desc: 'Stay up to date with the latest news, trends,<br />and insights in our blog',
        see_all: 'See all',
        search: 'Search',
        rubric_all: 'All articles',
        rubric_news: 'News',
        rubric_how_to: 'How To',
        latest_posts: 'Latest posts',
        no_results_found: 'No results found',
        all_results: 'All results',
        social_title: 'Did you find this post helpful?',
        social_desc: 'Share the post or subscribe to our newsletter',
        subscribe_btn: 'Subscribe',
    },
    common: {
        email: 'E-mail',
        name: 'Name',
        form_comment: 'Let us know what’s on your mind',
        send: 'Send',
        i_agree_with_1: 'I agree with',
        i_agree_with_2: 'Privacy Policy',
        message_show_all_errors: 'Please correct the indicated errors',
        learn_more: 'Learn more',
        free_download: 'Free download',
        download: 'Download',
        years: 'years',
        on_market: 'on the market',
        app_downloads: 'app downloads',
        average_rate: 'average rate',
        page_contact__desc: 'If you have any questions, comments, or concerns, please don\'t hesitate to get in touch with us.',
        contact_form__file_desc_1: 'Drag and Drop here',
        contact_form__file_desc_2: 'or Browse file',
        thank_you: 'Thank you',
        modal_successfully_submitted: 'The form was submitted successfully!',
        modal_successfully_subscribed: 'You have been successfully subscribed',
        modal_successfully_sent: 'The form was submitted successfully',
        table_of_contents: "Table of contents",
        updated: "Updated",
        contact_us: "Contact us",
        still_have_questions: "Still have questions?",
        still_have_questions__msg: "Contact us and we’ll come back to you ASAP.",
        published: 'Published',
        coockie_desc: 'This website uses cookies to ensure you get the best experience on our website.',
        coockie_btn: 'Accept cookies',
    },
    languages: {
        language: 'Language',
        english: 'English',
        russian: 'Russian',
        ukrainian: 'Ukrainian',
    },
}
export default translate
